<template>
<div>
  <div v-show='payStatus == "paid"'
    id='receipt-print' class='receipt-print relative border' style='height: 10.95in; width: 8.5in;'>
    <div class=''>
      <div class='bg-gray-100 w-full p-12'>
        <h1 class='text-5xl font-bold text-gray-700 text-center'>영수증</h1>
      </div>
      <div 
        class='flex flex-col justify-center items-center gap-y-1 z-10 p-4 relative'
        style='font-family: serif;'>
        <div class='w-full text-2xl px-8 mt-16'>
          <div class='flex flex-row justify-between py-3 border-t'>
            <div class='font-semibold text-gray-800 flex-shrink-0'>성명</div>
            <div class='text-right'>{{nameString}}</div>
          </div>
          <div class='flex flex-row justify-between py-3 border-t'>
            <div class='font-semibold text-gray-800 flex-shrink-0'>소속</div>
            <div class='text-right'>{{hospitalName}}</div>
          </div>
          <div class='flex flex-row justify-between py-3 border-t'>
            <div class='font-semibold text-gray-800 flex-shrink-0'>Registration Fee</div>
            <div class='text-right'>{{registrationFee}}</div>
          </div>
          <div class='flex flex-row justify-between py-3 border-t'>
            <div class='font-semibold text-gray-800 flex-shrink-0'>품목</div>
            <div class='text-right'>2022 한국산업보건학회 하계학술대회</div>
          </div>
          <div class='flex flex-row justify-between py-3 border-t'>
            <div class='font-semibold text-gray-800 flex-shrink-0'>기간</div>
            <div class='text-right'>2022.8.24 (수) ~ 8.26 (금)</div>
          </div>
          <div class='flex flex-row justify-between py-3 border-t border-b'>
            <div class='font-semibold text-gray-800 flex-shrink-0'>**재방송시청포함</div>
            <div class='text-right'>2022.8.27 (토) ~ 8.31 (수)</div>
          </div>
          <div class='text-lg mt-20 text-center'>
            상기 금액을 정히 영수함
          </div>
          <div class='text-lg p-8 mb-8 text-center'>
            2022년 8월 26 (금)
          </div>
          <div class='text-2xl font-semibold text-center' style='font-family: sans-serif;'>
            <img id='seal-image' :src='sealImage' class='inline-block max-w-md'/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if='payStatus == "unpaid"'>
     <div class='max-w-2xl p-24'>This participant hasn't paid yet</div>
  </div>
  <div v-else-if='payStatus == "unknown"'>
    <div class='max-w-2xl p-24'>Please check your license number and name</div>
  </div>
  <div v-else-if='payStatus!=="paid"'>
    <div class='max-w-2xl p-24'><loader-icon class='spinner inline-block' /> Loading</div>
  </div>
</div>
</template>

<script>
import { LoaderIcon } from 'vue-tabler-icons'
import { mapActions } from 'vuex'
import html2pdf from 'html2pdf.js'
import imageToBase64 from 'image-to-base64/browser'
import dayjs from 'dayjs'

export default {
  name: 'ReceiptDownloadFile',
  components: {
    LoaderIcon,
  },
  props: [
    'layout',
  ],
  data () {
    return {
      receiptData: {},
      payStatus: ''
    }
  },
  computed: {
    pdfOptions () {
      return {
        margin:       0,
        filename:     `${this.receiptData.name}_receipt.pdf`,
        image:        { type: 'png', quality: 0.98 },
        html2canvas:  { scale: 2 },
        jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
      }
    },
    issueDate () {
      return `${dayjs().format('YYYY-MM-DD')}`
    },
    registrationFee () {
      return this.receiptData.registrationData.registrationFee
    },
    nameString () {
      return `${this.receiptData.name}`
    },
    hospitalName () {
      return this.receiptData.hospitalName
    },
    sealImage () {
      return `https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/kiha/seal.png`
    }
  },
  methods: {
    ...mapActions('users', [
      'getPaidRegistrationDetailsForMe',
    ]),
    pdfCreate () {
      var element = document.getElementById('receipt-print')
      html2pdf().set(this.pdfOptions).from(element).save()
    },
  },
  mounted () {
    this.$emit('update:layout', 'empty-page')
    this.getPaidRegistrationDetailsForMe().then(resp => {
      if (resp.participantUnknown) {
        this.payStatus = 'unknown'
      } else if (resp.registrationData.payment) {
        this.receiptData = resp
        this.payStatus = 'paid'
        imageToBase64(this.sealImage).then(resp => {
        let image = document.getElementById('seal-image')
        image.src = `data:image/jpg;base64, ${resp}`
        image.onload = () => {
            this.pdfCreate()
          }
        })
      } else {
        this.payStatus = 'unpaid'
      }
    })
    
  },
  beforeDestroy () {
    this.$emit('update:layout', 'default-layout')
  },
}
</script>

<style lang='scss' scoped>
.spinner {
  animation: spin 1.5s linear infinite;
}
@keyframes spin {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>